<template>
  <base-vertical-container
    :items="items"
    :total="total"
    :placeholder="placeholder"
    :placeholder-message="$t('available.message', { itemName: $t('posts.title') })"
    :per-page="perPage"
    :loading="loading"
    :loading-next-page="isLoadingNextPage"
    @load-next="handleLoadNextPage"
  >
    <template #item="{ item }">
      <post-row
        :post="item"
        class="mb-1 mb-lg-2 mb-xl-3"
        :enable-card-size="enableCardSize"
        :disable-store="true"
        @edit-post="handleEditPost"
        @toggle-like="handleToggleLike"
        @comment-created="commentCreated"
      />
    </template>
    <template #footer>
      <post-creation-form-modal v-model="isEditingPost" :post="postToEdit" @posts-updated="$emit('posts-updated')" />
    </template>
  </base-vertical-container>
</template>

<script>
import Vue from 'vue';
import PostsPlaceholder from '@/assets/images/placeholders/light/posts.svg';
import Service from '@/config/service-identifiers';

import ItemsListMixin from '@core/components/containers/mixins/ItemsListMixin';

import BaseVerticalContainer from '@core/components/containers/base/BaseVerticalContainer.vue';
import CardWidgetMixin from '@core/mixins/widgets/CardWidgetMixin';
import OrderBy from '@core/constants/OrderBy';
import PostCreationFormModal from './post-creation/PostCreationFormModal.vue';
import PostRow from './PostRow.vue';

export default {
  name: 'PostsList',
  components: { PostRow, BaseVerticalContainer, PostCreationFormModal },
  mixins: [CardWidgetMixin, ItemsListMixin],
  props: {
    userKey: {
      type: String,
      required: true,
    },
    perPage: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      fetchedTotal: 0,
      postToEdit: null,
      isEditingPost: false,
    };
  },
  computed: {
    placeholder() {
      return PostsPlaceholder;
    },
    total() {
      return this.fetchedTotal;
    },
  },
  methods: {
    async loadPage({ page, perPage }) {
      const response = await this.$store.$service[Service.BackendClient].get('/posts', {
        params: {
          communityKey: this.$store.getters.currentCollective.key,
          orderByCreationTime: OrderBy.DESC,
          userKey: this.userKey,
          page,
          perPage,
        },
      });
      // Used to add back to copernicData Store the posts. It will be removed once posts are moved to the store.
      this.$store.commit('ADD_UNPAGINATED_ITEMS', { type: 'posts', items: response.data.data });

      this.fetchedTotal = response.data.meta.total;

      return response.data.data;
    },
    handleEditPost(post) {
      this.postToEdit = post;
      this.isEditingPost = true;
    },
    async handleToggleLike(post) {
      // FIXME: This method should be modified when post is removed from copernicData,
      // so that all lists of posts use the same logic
      const postIndex = this.items.findIndex(({ key }) => key === post.key);

      const query = post.likedByMember ? 'takeLike' : 'giveLike';

      await this.$store.$service[Service.BackendClient].post(
        query,
        { key: post.key, morphType: 'post' },
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } },
      );

      Vue.set(this.items, postIndex, {
        ...this.items[postIndex],
        likedByMember: !this.items[postIndex].likedByMember,
        totalLikes: this.items[postIndex].totalLikes + (this.items[postIndex].likedByMember ? -1 : +1),
      });
    },
    commentCreated(post) {
      // FIXME: This method should be modified when post is removed from copernicData,
      // so that all lists of posts use the same logic
      const postIndex = this.items.findIndex(({ key }) => key === post.key);

      Vue.set(this.items, postIndex, {
        ...this.items[postIndex],
        commentCount: this.items[postIndex].commentCount + 1,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.posts-list {
  max-width: 910px;
  margin: auto;
}
</style>
